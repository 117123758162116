<template>
  <div>
    <tr>
      <th
        colspan="100"
        class="d-none d-print-table-cell rounded pb-2 px-2"
        style="border: 1px solid #000; opacity: 1; color: #000"
      >
        <table>
          <tr>
            <th style="width: 20%; position: relative">
              <img
                src="../../assets/image 1.jpeg"
                width="100%"
                alt=""
              >

              <img
                src="../../assets/fb_logo.png"
                width="20px"
                alt=""
                style="position: absolute; bottom: -11px; right: 30px"
              >

              <img
                src="../../assets/WhatsApp_logo.png"
                width="25px"
                class="ms-2"
                alt=""
                style="position: absolute; bottom: -13px; right: 50px"
              >
            </th>
            <th
              class="text-center"
              style="letter-spacing: -0.7px !important"
            >
              <span
                style="font-size: 2rem !important;"
                class="d-block pb-2 error--text font-weight-bold"
              >
                پێشانگای چرای ماڵ
              </span>
              <span style="font-size: 1.2rem">
                بۆ فرۆشتنی کەل و پەلی ناوماڵ بە قیست و نەقد
              </span>
            </th>
            <th style="width: 20%; position: relative">
              <img
                src="../../assets/imaeg 2.jpeg"
                width="100%"
                alt=""
              >

              <img
                src="../../assets/Telegram_logo.webp"
                width="20px"
                alt=""
                style="position: absolute; bottom: -13px; left: 25px"
              >

              <img
                src="../../assets/tiktok_logo.webp"
                width="20px"
                alt=""
                style="position: absolute; bottom: -13px; left: 55px"
              >
            </th>
          </tr>
        </table>

        <table class="mt-n3">
          <tr>
            <th
              colspan="3"
              class="font-weight-bold"
              style="font-size: 1.1rem !important; letter-spacing: -0.7px !important; width: 57%"
            >
              هەولێر دارەتوو سێیەم ترافیک بەرامبەر قوتابخانەی شێروانە
            </th>
          </tr>

          <tr>
            <th
              class="font-weight-bold text-start dir-ltr"
              style="font-size: 1.1rem !important;"
            >
              <span class="dir-ltr ps-1">
                0750 440 1615
              </span>
              دوکان
            </th>
            <th
              class="font-weight-bold text-end ps-14 dir-ltr"
              style="font-size: 1.1rem !important;"
            >
              <span class="dir-ltr ps-1">
                0750 444 5844
              </span>
              خاوەنی  
            </th>
          </tr>
        </table>

        <table class="mt-n1">
          <tr>
            <th class="text-subtitle-1 text-start">
              <div class="d-inline-block grey lighten-2 px-2 rounded-sm">
                {{ $t('Date') }}: {{ $_format_date(date) }}
              </div>
            </th>
            <th class="text-subtitle-1 text-end">
              <div class="d-inline-block grey lighten-2 px-2 rounded-sm">
                {{ $t('Invoice No.') }}: {{ invoiceNo }}
              </div>
            </th>
          </tr>
        </table>
      </th>
    </tr>
  </div>
</template>

<script>
export default {
  props: {
    date: { type: [String, Date], default: () => new Date() },
    invoiceNo: { type: String, default: null },
  },
}
</script>

<style lang="scss" scoped>
</style>